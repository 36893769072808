import React, { useState } from 'react';
import { Collection } from 'types/collection.type';
import AssetManagementCollectionTileDisplay from './display';
import { useAMNavigation } from '../../../hooks';

import '../styles/main.scss';
import AMCheckbox from '../../am-checkbox';

interface Props {
    collection: Collection;
    collectionTileThumbnails?: React.ReactElement;
}

const AssetManagementCollectionTile = ({ collection, collectionTileThumbnails }: Props) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const { openCollection } = useAMNavigation();

    return (
        <AssetManagementCollectionTileDisplay
            collection={collection}
            collectionTileThumbnails={collectionTileThumbnails}
            hovered={hovered}
            className="asset-management-selectable-asset"
            onHandleClick={() => openCollection(collection.id)}
            onSetHovered={setHovered}>
            <AMCheckbox muiColorScheme="dark" color="default" collection={collection} className="asset-management-collection-tile__checkbox" />
        </AssetManagementCollectionTileDisplay>
    );
};

export default AssetManagementCollectionTile;

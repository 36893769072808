import React, { ReactNode, MouseEvent } from 'react';

interface StopPropagationWrapperProps {
    children: ReactNode;
}

const StopPropagationWrapper: React.FC<StopPropagationWrapperProps> = ({ children }) => {
    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return <span onClick={handleClick}>{children}</span>;
};

export default StopPropagationWrapper;
